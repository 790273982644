import Avatar from "@mui/material/Avatar";

export function TestimonialCard(props) {
  return (
    <div className="p-6 bg-white">
      <div className="flex items-start gap-16">
        <div className="relative">
          <AvatarSvg imageSrc={props.item.image} />
          <div className="absolute top-3 right-4 transform translate-x-1/2 -translate-y-1/2">
            <LittleCircle />
          </div>
        </div>

        <div className="flex-1">
          <blockquote className="text-gray-700 mb-4 text-base leading-relaxed">
            {props.item.text}
          </blockquote>
          <div>
            <h4 className="font-semibold text-gray-900">{props.item.author}</h4>
            <p className="text-sm text-gray-500">{props.item.title}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

const AvatarSvg = ({ imageSrc }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="100"
    viewBox="0 0 200 200"
    fill="none"
  >
    <circle
      style={{ mixBlendMode: "multiply" }}
      opacity="0.35"
      cx="100"
      cy="100"
      r="100"
      fill="url(#paint0_linear_1776_4418)"
    />
    <image
      href={imageSrc}
      x="0"
      y="0"
      height="200"
      width="200"
      clipPath="circle(100px at 100px 100px)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1776_4418"
        x1="197.282"
        y1="191"
        x2="-33.6142"
        y2="121.099"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFFBFE" />
        <stop offset="0.38" stopColor="#ECD7F3" />
        <stop offset="0.74" stopColor="#9364A8" />
        <stop offset="1" stopColor="#9364A8" />
      </linearGradient>
    </defs>
  </svg>
);

const LittleCircle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 48 48"
    fill="none"
  >
    <circle cx="24" cy="24" r="24" fill="url(#paint0_linear_1776_4416)" />
    <defs>
      <linearGradient
        id="paint0_linear_1776_4416"
        x1="0"
        y1="24"
        x2="48"
        y2="24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#742598" />
        <stop offset="0.333333" stopColor="#DEB2ED" />
        <stop offset="0.666667" stopColor="#9E68B6" />
        <stop offset="1" stopColor="#D5ACE3" />
      </linearGradient>
    </defs>
  </svg>
);
