import Link from "next/link";
import { motion } from "framer-motion";
import { useEffect, useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Testemonial } from "../UI/components/Box/Testemonial";
import { TestimonialCard } from "../UI/components/Box/TestimonialCard";
import { EffectFade, Autoplay } from "swiper";
import { HeaderHome } from "../UI/components/Box/HeaderHome";
import LivePricing from "../UI/components/Box/LivePricingData";
import { useTranslation } from "next-i18next";
import { WhatsAppWidget } from "react-whatsapp-widget";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Head from "next/head";

import { Section } from "../UI/components/BadgeSection/Section";
import { Divider } from "../UI/components//Divider";
import { DividerSvg } from "../UI/components/DividerSvg";
import { CoimexBadge } from "../UI/components/Badge";
import Anim from "../UI/components/scrolling/index";

import "react-whatsapp-widget/dist/index.css";
const OurMilestone = [
  {
    title: "How Does Coimex Work?",
    text: "Coimex, Chain of Importers & Exporters, is a global digital platform building a trustable community of traders, wholesalers, retailers and manufacturers through annual membership. The Platform connects you with trustable traders worldwide and builds long-term partnerships using the latest technology of mobile and web applications.",
    image: "/images/web-industry24.webp",
  },
  {
    title: "Coimex For Exporter",
    text: "Exporters enjoy the easiest and fastest way to showcase their products to trustable customers worldwide. Staying connected will empower the Exporters by giving them the opportunity to expand their businesses while creating awareness within the different regions of the seven continents.",
    image: "/images/web-industry32.webp",
  },
  {
    title: "Coimex For Importers",
    text: "Importers enjoy the advantages of being visible to the variety of category-leader suppliers worldwide. Staying connected will empower the Importers by catching the real-time opportunities of their categories and becoming competitive in their countries.",
    image: "/images/web-industry2.webp",
  },
  {
    title: "Benefits of Coimex Trading Model",
    text: "Coimex, integrates technology into the business world to enable safe trade for the actors of international trade. The transparent and informative structure of Coimex application empowers traders around the globe. The platform allows the ecosystem to be independent and free from outside intervention in order to maximize the credibility of Coimex members.",
    image: "/images/web-industry54.webp",
  },
];

export default function Home() {
  const { t } = useTranslation("common");
  const { i18n } = useTranslation();
  const isEnglish = i18n.language === "en";

  const [variant, setVariant] = useState("variant1");

  const textContainerRef = useRef(null);
  const [scrollTop, setScrollTop] = useState(0);
  const maxTextScrollHeight = 840; // Adjust based on your design (gap between texts)

  // Handle scroll event
  const handleScroll = (e) => {
    e.preventDefault(); // Prevent default scroll behavior

    const newScrollTop = scrollTop + e.deltaY; // Update scroll position based on mouse wheel delta

    if (newScrollTop >= 0 && newScrollTop <= maxTextScrollHeight) {
      setScrollTop(newScrollTop); // Constrain scroll within bounds
    }
  };

  // Apply scroll transformation to the text container
  useEffect(() => {
    if (textContainerRef.current) {
      textContainerRef.current.style.transform = `translateY(-${scrollTop}px)`;
    }
  }, [scrollTop]);

  useEffect(() => {
    const timer1 = setTimeout(() => setVariant("variant2"), 100); // 100ms delay
    const timer2 = setTimeout(() => setVariant("variant3"), 1100); // 100ms + 1000ms

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, []);

  const variants = {
    variant1: { opacity: 0, x: 50 },
    variant2: {
      opacity: 1,
      x: 0,
      transition: { duration: 1, ease: "easeOut" },
    },
    variant3: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.8, ease: "easeOut" },
    },
  };

  const TestemonialData = [
    {
      author: t("testmonial.1"),
      title: t("testmonial.2"),
      text: t("testmonial.0"),
      image: "/images/AshrafAbuIssa.svg",
    },
    {
      author: t("testmonial.5"),
      title: t("testmonial.4"),
      text: t("testmonial.3"),
      image: "/images/AshrafAbuIssa.svg",
    },
    {
      author: t("testmonial.8"),
      title: t("testmonial.7"),
      text: t("testmonial.6"),
      image: "/images/AshrafAbuIssa.svg",
    },
    {
      author: t("testmonial.11"),
      title: t("testmonial.10"),
      text: t("testmonial.9"),
      image: "/images/AshrafAbuIssa.svg",
    },
  ];
  const OurMilestone = [
    {
      title: t("homePage.0"),
      text: t("homePage.1"),
      image: "/images/web-industry24.webp",
      isReversed: false,
    },
    {
      title: t("homePage.2"),
      text: t("homePage.3"),
      image: "/images/2.jpg",
      isReversed: true,
    },
    {
      title: t("homePage.4"),
      text: t("homePage.5"),
      image: "/images/1.jpg",
      isReversed: false,
    },
    {
      title: t("homePage.6"),
      text: t("homePage.7"),
      image: "/images/web-industry54.webp",
      isReversed: true,
    },
  ];
  const CoimexBadge = [
    {
      text: t("homePage.12"),
      image: "/images/ico1.svg",
      title: t("homePage.20"),
    },
    {
      text: t("homePage.13"),
      image: "/images/ico2.svg",
      title: t("homePage.21"),
    },
    {
      text: t("homePage.14"),
      image: "/images/ico3.svg",
      title: t("homePage.22"),
    },
  ];

  const coimexStatistics = [
    {
      title: t("homePage.24"),
      count: "50k+",
      description: t("homePage.27"),
    },

    {
      title: t("homePage.25"),
      count: "40+",
      description: t("homePage.28"),
    },
    {
      title: t("homePage.26"),
      count: "100+",
      description: t("homePage.29"),
    },
  ];

  const DISABLED = true;
  return (
    <>
      <Head>
        <title>Coimex</title>
        <meta name="description" content={t("meta.0")} />
        <meta name="keywords" content={t("meta.0")} />
        <meta itemProp="description" content={t("meta.0")} />
        <meta property="og:description" content={t("meta.0")} />
        <link rel="shortcut icon" href="/images/logo.png" />
      </Head>
      <div>
        <motion.div
          className="bg-cover flex items-center justify-start gap-1 bg-center bg-no-repeat sm:h-[650px] h-[360px] w-full sm:bg-[url('/images/Web-hero4.webp')] bg-[url('/images/hero-mobile12.png')]"
          initial="variant1"
          animate={variant}
          variants={variants}
        >
          {variant !== "variant3" ? (
            <motion.h1
              className="text-PrimaryE3 text-center text-2xl sm:hidden flex font-MontserratSemiBold mx-auto self-end mb-8"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              {t("homePage.8")}
            </motion.h1>
          ) : (
            <HeaderHome />
          )}
        </motion.div>
        <div className="bg-graybackground sm:hidden px-9 pt-9 pb-7 justify-center items-center flex flex-col">
          <h2 className="font-montserrat font-normal px-2 w-full text-base text-[#313033 ] leading-5">
            {t("homePage.18")}
          </h2>
          <a
            target="_blank"
            href="https://wa.me/905324479299"
            rel="noopener noreferrer"
            className=" bg-fontColorB  flex justify-center items-center w-full h-[42px] rounded-full text-white font-MontserratMedium text-base mt-5 "
          >
            <p className=" text-white font-MontserratMedium text-base">
              {t("homePage.10")}
            </p>
          </a>
          <div className="bg-graybackground border-2 w-full h-[42px] rounded-full border-fontColorB  mt-3">
            <Link
              href={
                isEnglish
                  ? "https://coimex.co/pricing?package=exporter"
                  : "https://coimex.co/tr/pricing?package=exporter"
              }
              className="border-2 w-full border-fontColorB  flex justify-center items-center h-full rounded-full  text-fontColorB font-MontserratMedium text-base"
            >
              <a
                rel="noopener noreferrer"
                className=" h-full   flex justify-center items-center rounded-full "
              >
                <p className=" text-fontColorB font-MontserratMedium text-base">
                  {t("homePage.11")}
                </p>
              </a>
            </Link>
          </div>
        </div>

        <div className="flex flex-col justify-center bg-white ">
          {/* New Hompage Card */}
          <div className="hidden md:flex md:flex-col m-auto md:justify-between w-full gap-y-2  md:w-11/12 mt-20 flex-wrap">
            <h1 className="placeholder: px-8 md:px-24 ">
              <span
                className="inline-block font-bold text-[48px] md:text-[60px] leading-[80px] font-MontserratBold"
                style={{
                  background:
                    "linear-gradient(90deg, #742598 0%, #DEB2ED 33.33%, #9E68B6 66.67%, #D5ACE3 100%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                {t("homePage.23")}
              </span>
              <br />
              <span
                className="inline-block font-semibold text-[40px] md:text-[56px] leading-[60px] font-montserrat"
                style={{
                  background:
                    "linear-gradient(90deg, #742598 0%, #DEB2ED 33.33%, #9E68B6 66.67%, #D5ACE3 100%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                {t("homePage.23/sub")}
              </span>
            </h1>

            <div className="w-full flex flex-wrap justify-center gap-x-24 gap-y-12 mt-6 mb-12">
              {coimexStatistics.map((item, i) => (
                <div
                  key={i}
                  className="w-[280px] md:w-[320px] h-auto flex flex-col "
                >
                  <h3 className="text-[56px] md:text-[80px] font-bold text-fontColorB font-montserrat">
                    {item.count}
                  </h3>
                  <div className=" mb-4">
                    <DividerSvg />
                  </div>
                  <h3 className="text-xl md:text-2xl font-semibold text-fontColorB font-montserrat">
                    {item.title}
                  </h3>
                  <p className="text-base text-fontColorB font-montserrat mt-2">
                    {item.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
          {/* New CoimexBadge */}
          <div className="hidden md:flex m-auto md:justify-between w-full mt-2 flex-wrap bg-[#EFF0F4]">
            <div className="w-full flex flex-wrap justify-center gap-x-24 gap-y-2 mt-6 md:mt-20 mb-6 md:mb-12 px-8 md:px-24">
              {CoimexBadge.map((item, i) => (
                <div
                  key={i}
                  className="w-[320px] rounded-x flex flex-col h-auto"
                >
                  <img
                    src={item.image}
                    alt={item.text}
                    className="w-[60px] h-[65px]"
                  />
                  <h3 className="mt-6 font-MontserratMedium text-2xl text-fontColorB font-medium whitespace-nowrap">
                    {item.title}
                  </h3>
                  <p className="mt-6 font-montserrat text-base text-fontColorB">
                    {item.text}
                  </p>
                </div>
              ))}
            </div>
          </div>

          <div
            className="w-full md:w-11/12 mx-auto py-12 px-8 md:px-24"
            style={{
              backgroundImage: 'url("/images/badge-background.svg")',
              backgroundPosition: "top",
              backgroundAttachment: "fixed",
              backgroundSize: "cover",
              height: "auto",
            }}
          >
            {/* Title Section */}
            <h1 className="font-bold mb-8">
              <span className="inline-block bg-trade-gradient bg-clip-text text-transparent text-[56px] md:text-6xl font-extrabold font-MontserratBold">
                {t("homePage.34")}
              </span>
              <br />
              <span className="inline-block bg-gradient-to-r from-[#9E68B6] via-[#D5ACE3] to-[#9E68B6] bg-clip-text text-transparent text-[56px] md:text-6xl font-extrabold font-MontserratBold leading-[70px]">
                {t("homePage.35")} &nbsp;
              </span>
              <span className="inline-block bg-gradient-to-r from-[#9E68B6] via-[#D5ACE3] to-[#9E68B6] bg-clip-text text-transparent text-4xl md:text-5xl  font-extrabold font-montserrat leading-[70px]">
                {t("homePage.36")} &nbsp;
              </span>
              <span className="inline-block bg-gradient-to-r from-[#9E68B6] via-[#D5ACE3] to-[#9E68B6] bg-clip-text text-transparent text-[56px] md:text-6xl font-extrabold font-MontserratBold leading-[70px]">
                {t("homePage.37")}
              </span>
            </h1>

            <div className="space-y-8">
              {OurMilestone.map((section, index) => (
                <div key={section.title}>
                  <Section {...section} />
                  {index < OurMilestone.length - 1 && <Divider />}
                </div>
              ))}
            </div>
          </div>

          <Anim />
          {/* 
          <div
            className="new-solutions-section"
            onWheel={handleScroll} 
            style={{ overflow: "hidden" }} 
          >
   
            <div className="image-container">
              <div className="md:h-[800px] bg-[url('/images/gif/homegif.gif')] bg-cover bg-center bg-no-repeat">
                <h1 className="flex flex-col">
                  <span
                    className="px-8 md:px-24 mt-[22px] font-montserrat text-[64px] inline-block text-white font-bold leading-[90px] font-feature-settings-liga-off-clig-off"
                    style={{
                      fontSize: "64px",
                      lineHeight: "96px",
                      fontWeight: 700,
                      fontFamily: "Montserrat",
                      fontStyle: "normal",
                    }}
                  >
                    {t("homePage.30")}
                  </span>
                  <span
                    className="px-8 md:px-24 inline-block text-white font-montserrat text-[48px] font-bold leading-[64px]"
                    style={{
                      fontSize: "48px",
                      lineHeight: "64px",
                      fontWeight: 700,
                      fontFamily: "Montserrat",
                      fontStyle: "normal",
                    }}
                  >
                    {t("homePage.31")}
                  </span>
                </h1>
              </div>
            </div>

            <div className="text-container" ref={textContainerRef}>
              <div className="text-content">
                <h2 className="text-fontColorB text-[24px] font-montserrat font-semibold mb-4">
                  {t("homePage.32")}
                </h2>
                <p className="text-fontColorB text-[20px] leading-relaxed font-montserrat font-normal">
                  {t("homePage.33")}
                </p>
              </div>
              <div className="text-content">
                <h2 className="text-fontColorB text-[24px] font-montserrat font-semibold mb-4">
                  {t("homePage.32")}
                </h2>
                <p className="text-fontColorB text-[20px] leading-relaxed font-montserrat font-normal">
                  {t("homePage.33")}
                </p>
              </div>
            </div>
          </div> */}

          <div className="md:hidden">
            <h2 className="w-11/12 m-auto mt-4 text-black flex md:hidden text-base font-MontserratSemiBold px-2">
              TESTIMONIAL
            </h2>
            <Swiper
              spaceBetween={30}
              effect={"fade"}
              autoplay={{
                delay: 10500,
                disableOnInteraction: false,
              }}
              modules={[EffectFade, Autoplay]}
              className="w-11/12 mt-4 mb-8"
            >
              {TestemonialData.map((item, index) => (
                <SwiperSlide
                  key={index}
                  className="bg-whiteLatic px-8 py-10 rounded-xl m-auto w-11/12 md:h-[214px] h-[360px]"
                >
                  {/* <Testemonial item={item} /> */}
                  <TestimonialCard item={item} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <div className="md:block hidden">
          <Swiper
            spaceBetween={30}
            effect={"fade"}
            autoplay={{
              delay: 10500,
              disableOnInteraction: false,
            }}
            modules={[EffectFade, Autoplay]}
            className="w-9/12 mt-4 mb-8"
          >
            {TestemonialData.map((item, index) => (
              <SwiperSlide
                key={index}
                className=" px-8 py-10 rounded-xl m-auto  w-9/12 md:h-[214px] h-[360px]"
              >
                {/* <Testemonial item={item} /> */}
                <TestimonialCard item={item} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
    },
  };
}
