"use client";

import { useEffect, useRef, useState } from "react";
import "../../../styles/styles.module.css";
import { useTranslation } from "next-i18next";

export default function Anim() {
  const { t } = useTranslation("common");
  const { i18n } = useTranslation();
  const componentRef = useRef(null);
  const textContainerRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const [shouldMoveToNextSection, setShouldMoveToNextSection] = useState(false);

  const textItems = [
    "First Say goodbye to uncertainties and delays. Our Escrow Smart Contract integrates seamlessly with the stages of trade transactions, following WTO Incoterms from pre-production to delivery at the buyer's warehouse. Parties involved decide when the initial payment occurs, documented in a secure sales agreement and recorded on the blockchain",
    "Second Say goodbye to uncertainties and delays. Our Escrow Smart Contract integrates seamlessly with the stages of trade transactions, following WTO Incoterms from pre-production to delivery at the buyer's warehouse. Parties involved decide when the initial payment occurs, documented in a secure sales agreement and recorded on the blockchain",
    "Third Say goodbye to uncertainties and delays. Our Escrow Smart Contract integrates seamlessly with the stages of trade transactions, following WTO Incoterms from pre-production to delivery at the buyer's warehouse. Parties involved decide when the initial payment occurs, documented in a secure sales agreement and recorded on the blockchain",
  ];

  useEffect(() => {
    const handleScroll = (e) => {
      if (isScrolling) return;
      e.preventDefault();

      setIsScrolling(true);
      const delta = e.deltaY;

      if (delta > 0) {
        // Scrolling down
        if (currentIndex < textItems.length - 1) {
          setCurrentIndex((prev) => prev + 1);
        } else if (
          currentIndex === textItems.length - 1 &&
          !shouldMoveToNextSection
        ) {
          setShouldMoveToNextSection(true);
          // Find the next section and scroll to it
          const nextSection = componentRef.current?.nextElementSibling;
          if (nextSection) {
            nextSection.scrollIntoView({ behavior: "smooth" });
          }
        }
      } else if (delta < 0) {
        // Scrolling up
        if (currentIndex > 0) {
          setCurrentIndex((prev) => prev - 1);
        }
      }

      setTimeout(() => {
        setIsScrolling(false);
      }, 500);
    };

    const component = componentRef.current;
    if (component) {
      component.addEventListener("wheel", handleScroll, { passive: false });
    }

    return () => {
      if (component) {
        component.removeEventListener("wheel", handleScroll);
      }
    };
  }, [currentIndex, isScrolling, shouldMoveToNextSection]);

  return (
    <div ref={componentRef} className="relative h-[800px] overflow-hidden">
      {/* Left Side: Fixed Background Image */}
      <div className="md:h-[800px] bg-[url('/images/gif/homegif.gif')] bg-cover bg-center bg-no-repeat">
        <h1 className="flex flex-col">
          <span
            className="px-8 md:px-24 mt-[22px] font-montserrat text-[64px] inline-block text-white font-bold leading-[90px] font-feature-settings-liga-off-clig-off"
            style={{
              fontSize: "64px",
              lineHeight: "96px",
              fontWeight: 700,
              fontFamily: "Montserrat",
              fontStyle: "normal",
            }}
          >
            {t("homePage.30")}
          </span>
          <span
            className="px-8 md:px-24 inline-block text-white font-montserrat text-[48px] font-bold leading-[64px]"
            style={{
              fontSize: "48px",
              lineHeight: "64px",
              fontWeight: 700,
              fontFamily: "Montserrat",
              fontStyle: "normal",
            }}
          >
            {t("homePage.31")}
          </span>
        </h1>
      </div>

      {/* Right Side: Scrollable Text Sections */}
      <div className="absolute top-0 right-0 w-1/3 h-full overflow-hidden bg-white">
        <div
          ref={textContainerRef}
          className="absolute  left-0 w-full transition-transform duration-500 ease-out"
          style={{ transform: `translateY(-${currentIndex * 800}px)` }}
        >
          {textItems.map((text, index) => (
            <div
              key={index}
              className="h-[800px] flex items-center mt-auto text-2xl text-black"
            >
              {text}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
